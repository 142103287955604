.home-container {
    width: 100%;
    height: 100%;
    flex: 1;
    flex-direction: column;
    background-color: rgba(12, 24, 78, 1);
    text-align: center;
    border: none;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 150px;
    background-color: rgb(21 23 35);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.navbar-logo {
    width: 350px;
    height: auto;
    transition: all 0.3s ease-in-out;
}

.navbar.scrolled .navbar-logo {
    width: 200px;
    height: auto;
}

.image-container {
    position: relative;
    display: inline-block;
    margin-top: 0;
}

.home-primary-image {
    width: 100%;
    height: auto;
}

.text-overlay {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
}

.text-overlay h1 {
    margin: 0;
    font-size: 50px;
    font-family: 'Monotype Century Old Style', serif;
}

.text-overlay p {
    margin: 0;
    margin-top: 10px;
    font-size: 20px;
    font-family: 'Monotype News Gothic', sans-serif;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.store-button {
    width: 200px;
    margin: 0 10px;
    transition: transform 0.2s;
}

.store-button:hover {
    transform: scale(1.05);
}

.offer-button-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.sales-button {
    padding: 12px 24px;
    background-color: black;
    color: white;
    font-size: 18px;
    border-radius: 10px;
    text-decoration: none;
    transition: transform 0.2s ease-in-out; /* Modification pour utiliser transform */
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.sales-button:hover {
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .navbar {
        height: 100px;
    }

    .home-container {
        flex-direction: column-reverse;
    }

    .image-container {
        margin-top: 80px;
        position: relative;
        z-index: 1;
    }

    .text-overlay {
        margin-top: 150px;
        position: relative;
        z-index: 2;
        background-color: white;
    }

    .text-overlay h1 {
        font-size: 30px;
    }

    .text-overlay p {
        font-size: 16px;
    }

    .store-button {
        width: 150px;
    }
}

@media (max-width: 480px) {
    .navbar {
        height: 80px;
    }

    .navbar-logo {
        width: 150px;
    }

    .navbar.scrolled .navbar-logo {
        width: 100px;
    }

    .text-overlay h1 {
        font-size: 24px;
    }

    .text-overlay p {
        font-size: 14px;
    }

    .store-button {
        width: 120px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .navbar {
        height: 120px;
    }

    .navbar-logo {
        width: 300px;
    }

    .navbar.scrolled .navbar-logo {
        width: 180px;
    }

    .text-overlay h1 {
        font-size: 40px;
    }

    .text-overlay p {
        font-size: 18px;
    }

    .store-button {
        width: 180px;
    }
}

@media (min-width: 1025px) {
    .navbar {
        height: 150px;
    }

    .navbar-logo {
        width: 350px;
    }

    .navbar.scrolled .navbar-logo {
        width: 200px;
    }

    .text-overlay h1 {
        font-size: 50px;
    }

    .text-overlay p {
        font-size: 20px;
    }

    .store-button {
        width: 200px;
    }
}
