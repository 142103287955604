@font-face {
    font-family: 'Monotype Century Old Style';
    src: url('../assets/fonts/CenturyOldStyleStd-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Monotype News Gothic';
    src: url('../assets/fonts/NewsGothicStd.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

:root {
    --primary-background: #F1F4F8;
    --secondary-background: #FFFFFF;
    --primary-text: #14181B;
    --secondary-text: #57636C;
    --blue-doger: #4A90E2;
}

::-webkit-scrollbar {
    display: none; /* hidde scroll bar */
}

body {
    background-color: #F1F4F8;
    overflow-y: auto;
}
