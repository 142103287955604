.profile-container {
    display: flex; /* display elements*/
    flex-direction: column; /* put each item one below the other */
    align-items: center; /* align center items horizontaly and vertically */
    width: 85%; /* the witdh of the container % of the page */
    height: 100vh; /* take 100% of the page in height */
    margin: 0 auto; /* center horizontale a element with defined witdh */
    margin-bottom: 50px;
}

@media (min-width: 1024px) {
    .profile-container {
        width: 40%;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .profile-container {
        width: 70%;
    }
}
