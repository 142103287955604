.phone-input-form {
    margin-top: 10px;
}

.phone-input-label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

.phone-input-field {
    width: 100% !important;
    height: 40px !important;
}
