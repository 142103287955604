.image-uploader {
    display: flex;
    width: 90%;
    margin: 0 auto 20px auto;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.image-uploader input[type="file"] {
    display: none;
}

.upload-button {
    border: 3px solid #e0e0e0;
    height: 20px;
    padding: 10px 15px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.upload-button:hover {
    border-color: var(--blue-doger) !important;
}

.upload-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.form-file-input {
    width: 250px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 15px;
}
