.social-links-row {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 8px;
    margin-bottom: 16px;
}

.social-icon {
    font-size: 40px !important;
    color: var(--primary-text);
}

@media (min-width: 1024px) {
    .social-icon {
        font-size: 55px !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .social-icon {
        font-size: 50px !important;
    }
}
