.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f0f0f0;
  min-height: 30vh;
}

.body-center {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.card {
  border: 1px solid #ddd;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: border 0.2s, background-color 0.2s;
}

.card:hover:not(.no-hover) {
  color: #0056b3;
  border: 1px solid #0056b3;
  background-color: #e9f5ff;
}

.order-details ul {
  list-style-type: none;
  padding: 0;
}

.order-details li {
  padding: 10px;
  margin-bottom: 5px;
}

.order-details li.even {
  background-color: #b6b6e9;
}

.order-details li.odd {
  background-color: #26c4e05e;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.button {
  margin-top: 10px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.send-button {
  background-color: #007bff;
  color: white;
}

.send-button:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.send-button-h1 {
  background-color: #28a745;
  color: white;
}

.send-button-h1:hover {
  background-color: #218838;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.order-table th, .order-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.order-table th {
  background-color: #f2f2f2;
}

.status-group {
  margin-bottom: 20px;
}

.customer-avatar {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.customer-info {
  margin-top: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.toggle-details {
  margin-top: 10px;
  font-weight: bold;
}

.toggle-details span {
  cursor: pointer;
  color: #007bff;
}

.close-icon-container {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.close-icon-container:hover {
  background-color: #f0f0f0;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
