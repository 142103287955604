.container-color-selector {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto 20px auto;
}

.header-color-selector {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
}

.header-color-selector > * {
    margin-right: 10px;
}

.header-color-selector > :last-child {
    margin-right: 0;
}

.color-input-container {
    display: flex;
}

.color-input-container select {
    width: 25%;
    margin-right: 20px;
}

.color-input-container input {
    width: 50%;
}

.color-input-container select:focus {
    border-color: var(--blue-doger);
    outline: none;
}

.color-input-container input:focus {
    border-color: var(--blue-doger);
    outline: none;
}

.form-select-input {
    width: 250px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 15px;
}
