.progress-bar-order {
    background: #e0e0e0;
    border-radius: 8px;
    height: 10px;
    margin-bottom: 20px;
    width: 90%;
    margin: 0 auto 20px auto;
}

.progress {
    background: var(--blue-doger);
    height: 100%;
    border-radius: 8px;
}
