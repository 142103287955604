.order-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 150px;
    gap: 20px;
}

.logo-order {
    margin-top: 20px;
    width: 300px;
}

.order-reference-title {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    margin: 0;
    font-weight: 700;
}
