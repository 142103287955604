.confirm-cards-button {
    padding: 10px 20px;
    width: auto;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    margin: 0 auto;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
}

.confirm-cards-button:hover {
    background-color: #45A049;
}

.confirm-button-container {
    width: 90%;
    margin: 0 auto 20px auto;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}

.error-message {
    color: red;
    font-size: 14px;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    font-family: 'Roboto', sans-serif;
}

.error-message.visible {
    opacity: 1;
}
