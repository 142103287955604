.email-input-form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.email-input-label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

.email-input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
}

.email-input-field:focus {
    outline: none;
}

.required {
    color: red;
}
