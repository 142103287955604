.message-input-form {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}

.message-input-label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

.message-input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    resize: none;
}

.message-input-field:focus {
    outline: none;
}
