.banner-container {
  position: relative;
  width: 100%;
  height: 195px;
  margin: 0 auto;
  position: relative;
}

.banner-image {
  width: 100%;
  height: 150px;
  border-radius: 20px;
  object-fit: cover;
}

.profile-image {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 4px solid white;
  box-shadow: 0 0 0 2px var(--blue-doger);
  object-fit: cover;
}

@media (min-width: 1024px) {
  .banner-container {
    height: 390px;
  }
  .banner-image {
    height: 300px;
  }
  .profile-image {
    width: 200px;
    height: 200px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .banner-container {
    height: 350px;
  }
  .banner-image {
    height: 270px;
  }
  .profile-image {
    width: 180px;
    height: 180px;
  }
}
