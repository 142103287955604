.name-row {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    margin-top: 8px;
    gap: 4px;
    margin-bottom: 12px;
}


@media (min-width: 1024px) {
    .name-row {
        font-size: 34px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .name-row {
        font-size: 32px;
    }
}

