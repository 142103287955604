.navigation-buttons {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto 20px auto;
}

.navigation-buttons button {
    padding: 10px 15px;
    background-color: var(--blue-doger);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.navigation-buttons button:hover {
    background-color: #0056b3;
}

.navigation-buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
