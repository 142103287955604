
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 15px rgba(0, 0, 255, 0.5);
    background-color: white;
}

.login-input {
    margin-bottom: 10px;
}

.login-error {
    color: red;
}

.login-button {
    margin-top: 10px;
}
