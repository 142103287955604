.error-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 300px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.error-logo {
    width: 250px;
    height: auto;
    margin-bottom: 25px;
}

.error-title {
    font-weight: bold;
    font-size: 24px;
    color: var(--blue-doger);
}

.error-message-found {
    font-size: 16px;
    color: #6c757d;
    margin-top: 10px;
    text-align: center;
}

@media (min-width: 1024px) {
    .error-container {
        width: 400px;
    }
    .error-logo {
        width: 400px;
        height: auto;
        margin-bottom: 25px;
    }
    .error-title {
        font-size: 34px;
    }
    .error-message {
        font-size: 22px;
        color: #6c757d;
        margin-top: 10px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .error-container {
        width: 400px;
    }
    .error-logo {
        width: 400px;
        height: auto;
        margin-bottom: 25px;
    }
    .error-title {
        font-size: 32px;
    }
    .error-message {
        font-size: 16px;
        color: #6c757d;
        margin-top: 10px;
    }
}