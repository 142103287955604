.name-input-form {
    width: 90%;
    margin: 0 auto 20px auto;
    align-items: center;
    justify-content: center;
}

.row-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0;
}

.input-group {
    flex: 1;
    margin-right: 20px;
}

.input-group:last-child {
    margin-right: 0px;
}

.custom-card-input {
    width: 250px;
    padding: 10px;
    border: 2px solid #e0e0e0;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.custom-card-input:focus {
    outline: none;
    border-color: var(--blue-doger);
}


@media (max-width: 768px) {
    .row-name {
        flex-direction: column;
        align-items: flex-start;
    }

    .input-group {
        margin-right: 0;
    }

    .input-group:last-child {
        margin-bottom: 0;
    }

    .custom-card-input {
        font-size: 14px;
        padding: 8px;
    }
}