.name-inputs {
    display: flex;
    gap: 10px;
    width: 100%;
}

.name-input-group {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.name-input-label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

.name-input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
}

.name-input-field:focus {
    outline: none;
}

.error-message-form {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}
