.description-text {
    text-align: center;
    margin: 0 auto;
    width: 90%;
    font-size: 16px;
}

@media (min-width: 1024px) {
    .description-text {
        width: 70%;
        font-size: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .description-text {
        width: 70%;
    }
}

