.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--primary-background);
}

.loading-logo {
    animation: pulse 2s infinite ease-in-out;
    width: 400px;
}

@keyframes pulse {
    0% {
        opacity: 0.5;
        transform: scale(1);
    }
    50% {
        opacity: 1;
        transform: scale(1.2);
    }
    100% {
        opacity: 0.5;
        transform: scale(1);
    }
}

@media (min-width: 1024px) {
    .loading-logo {
        width: 600px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .loading-logo {
        width: 600px;
    }
}
