.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left-button {
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
}

.header-center {
  flex-grow: 1;
  text-align: center;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right p {
  margin: 0 10px 0 0;
}

.header-right-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
