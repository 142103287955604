.floating-button {
    position: fixed;
    z-index: 1000;

    cursor: pointer;
    border-radius: 50%;

    left: 50%;
    transform: translateX(-50%);

    bottom: 20px;

    width: 50px;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    background-color: var(--primary-background);
    color: var(--primary-text);
    border: 2px solid var(--blue-doger);
}

.icon-send {
    font-size: 26px;
    color: color(--primary-text);
}

@media (min-width: 1024px) {
    .floating-button {
        width: 60px;
        height: 60px;
    }
    .icon-send {
        font-size: 32px;
    }
}
