.submit-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.submit-button {
    background-color: var(--blue-doger);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
    transform: scale(1.1);
}

.submit-button:active {
    transform: scale(1);
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.6;
}
