.order-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container-order {
    display: flex;
    flex-direction: column;
    width: 600px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background: white;
    overflow: auto;
}

@media (max-width: 768px) {
    .container-order {
        margin-top: 10px;
        width: 98%;
    }
}

label {
    display: block;
    margin-bottom: 5px;
}
