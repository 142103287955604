.contact-button {
    background-color: var(--primary-background);
    color: var(--primary-text);
    border: 2px solid var(--blue-doger);
    margin-top: 24px;
    width: 70%;
    height: 52px;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 14px;
    cursor: pointer;
}

.button-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.contact-text {
    text-align: center;
    font-size: 15px;
}

.contact-icon {
    font-size: 34px !important;
}

@media (min-width: 1024px) {
    .contact-button {
        width: 50%;
        height: 60px;
    }
    .contact-icon {
        font-size: 42px !important;
    }
    .contact-text {
        font-size: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .contact-button {
        width: 55%;
        height: 65px;
    }
    .contact-icon {
        font-size: 40px !important;
    }
    .contact-text {
        text-align: center;
        font-size: 20px;
    }
}
