.title-form-style {
    text-align: center;
    margin: 0 auto;

    font-size: 19px;
    font-weight: bold;
    color: var(--blue-doger)
}


@media (min-width: 1024px) {
    .title-form-style {
        font-size: 24px;
    }
}
