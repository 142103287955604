.custom-links-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 100%;
}

.custom-button {
    background-color: white;
    color: var(--primary-text);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 50px;
    margin: 0 auto;
    text-decoration: none;
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

@media (min-width: 1024px) {
    .custom-button {
        width: 70%;
        height: 60px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .custom-button {
        width: 70%;
        height: 60px;
    }
}
